import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import * as echarts from 'echarts';
import WDatePicker from '@/components/w/components/w-date-picker/index.vue';
import dayjs from 'dayjs';
export default {
  components: {
    WDatePicker
  },
  data() {
    return {
      yesterdayQuery: {
        created_at_between: [dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 00:00:00')],
        _with_total: 1
      },
      echartQuery: {
        created_at_between: [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        site_id_match: undefined
      },
      topUserQuery: {
        created_at_between: [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      },
      siteQuery: {
        created_at_between: [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      },
      transQuery: {
        created_at_between: [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
      },
      siteList: [],
      yesterday: [],
      echartList: [],
      topUser: [],
      site: [],
      trans: [],
      userTotal: {},
      myChart: undefined,
      totalAmount: 0,
      totalOrderCount: 0
    };
  },
  mounted() {
    this.$w_fun.post('report/site').then(res => {
      this.siteList = res.data.list;
    });
    this.getUserTotal();
    this.getYesterday();
    this.getEchart();
    this.getTopUser();
    this.getSite();
    this.getTrans();
  },
  methods: {
    getUserTotal() {
      this.$w_fun.post('report/user').then(res => {
        this.userTotal = res.data;
      });
    },
    getYesterday() {
      this.$w_fun.post('report/amount', this.yesterdayQuery).then(res => {
        this.yesterday = res.data.list;
      });
    },
    getEchart() {
      this.$w_fun.post('report/amountDay', this.echartQuery).then(res => {
        this.echartList = res.data.list;
        this.totalAmount = this.echartList.paid_amount.reduce((c, i) => c + i, 0);
        this.totalOrderCount = this.echartList.order_count.reduce((c, i) => c + i, 0);
        this.initChart();
      });
    },
    getTopUser() {
      this.$w_fun.post('report/topUser', this.topUserQuery).then(res => {
        this.topUser = res.data.list;
      });
    },
    getSite() {
      this.$w_fun.post('report/amount', this.siteQuery).then(res => {
        this.site = res.data.list;
      });
    },
    getTrans() {
      this.$w_fun.post('report/trans', this.transQuery).then(res => {
        this.trans = res.data.list;
      });
    },
    initChart() {
      if (!this.myChart) {
        let chartDom = document.getElementById('chart');
        this.myChart = echarts.init(chartDom);
      }
      let options = {
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: [{
          type: 'value',
          name: '金额(日元)',
          alignTicks: true
        }, {
          type: 'value',
          name: '订单量',
          alignTicks: true
        }],
        tooltip: {
          trigger: 'axis'
        },
        series: [],
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 10,
          top: 20,
          bottom: 20
        }
      };
      for (let i = 0;; i++) {
        let nn = dayjs(this.echartQuery.created_at_between[0]).add(i, 'day');
        options.xAxis.data.push(nn.format('MM-DD'));
        if (nn.toDate().getTime() >= dayjs(this.echartQuery.created_at_between[1]).toDate().getTime()) {
          break;
        }
      }
      options.series.push({
        data: this.echartList.paid_amount,
        type: 'line',
        name: '金额',
        smooth: true,
        yAxisIndex: 0
      });
      options.series.push({
        data: this.echartList.order_count,
        name: '订单量',
        type: 'line',
        smooth: true,
        yAxisIndex: 1
      });
      this.myChart.setOption(options);
    },
    disposeChart() {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
    }
  },
  beforeDestroy() {
    this.disposeChart();
  }
};