var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p15 w-main-content"
  }, [_c("div", {
    staticClass: "dp-f align-items-center w-data-statist"
  }, [_vm._l(_vm.yesterday, function (item) {
    return _c("div", {
      staticClass: "w-item"
    }, [_c("div", {
      staticClass: "dp-f flex-d-c"
    }, [_c("div", {
      staticClass: "w-r"
    }, [_c("span", {
      staticClass: "f14"
    }, [_vm._v(_vm._s(item.site) + "昨日单量")]), _c("i", {
      staticClass: "f20 b"
    }, [_vm._v(_vm._s(item.order_count))])]), _c("div", {
      staticClass: "w-r"
    }, [_c("span", {
      staticClass: "f14"
    }, [_vm._v(_vm._s(item.site) + "昨日交易额")]), _c("i", {
      staticClass: "f20 b"
    }, [_vm._v(_vm._s(item.paid_amount))])]), _c("div", {
      staticClass: "w-r"
    }, [_c("span", {
      staticClass: "f14"
    }, [_vm._v(_vm._s(item.site) + "昨日客单价")]), _c("i", {
      staticClass: "f20 b"
    }, [_vm._v(_vm._s(item.order_count ? (item.paid_amount / item.order_count).toFixed(0) : 0))])])])]);
  }), _c("div", {
    staticClass: "w-item"
  }, [_c("div", {
    staticClass: "dp-f flex-d-c"
  }, [_c("div", {
    staticClass: "dp-f flex-d-c"
  }, [_c("div", {
    staticClass: "w-r"
  }, [_c("span", {
    staticClass: "f14"
  }, [_vm._v("用户总量")]), _c("i", {
    staticClass: "f20 b"
  }, [_vm._v(_vm._s(_vm.userTotal.total))])]), _c("div", {
    staticClass: "w-r"
  }, [_c("span", {
    staticClass: "f14"
  }, [_vm._v("本周新增")]), _c("i", {
    staticClass: "f20 b"
  }, [_vm._v(_vm._s(_vm.userTotal.week))])])])])])], 2), _c("section", [_c("div", {
    staticClass: "dp-f justify-content-between f-chart-search"
  }, [_c("div", {
    staticClass: "dp-f-c-c"
  }, [_c("span", [_vm._v("筛选商城：")]), _c("w-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      data: _vm.siteList
    },
    on: {
      change: _vm.getEchart
    },
    model: {
      value: _vm.echartQuery.site_id_match,
      callback: function ($$v) {
        _vm.$set(_vm.echartQuery, "site_id_match", $$v);
      },
      expression: "echartQuery.site_id_match"
    }
  })], 1), _c("div", [_c("span", [_vm._v("筛选日期：")]), _c("w-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "daterange",
      clearable: false
    },
    on: {
      change: _vm.getEchart
    },
    model: {
      value: _vm.echartQuery.created_at_between,
      callback: function ($$v) {
        _vm.$set(_vm.echartQuery, "created_at_between", $$v);
      },
      expression: "echartQuery.created_at_between"
    }
  })], 1), _c("div", [_c("p", {
    staticClass: "pr20 tr"
  }, [_vm._v("合计 - 金额:" + _vm._s(_vm.totalAmount) + "日元  订单量:" + _vm._s(_vm.totalOrderCount))])])]), _c("div", {
    ref: "chart",
    staticClass: "f-chart",
    attrs: {
      id: "chart"
    }
  })]), _c("section", [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("导出表格")]), _c("div", {
    staticClass: "dp-f justify-content-between"
  }, [_c("div", {
    staticStyle: {
      width: "32%"
    }
  }, [_c("div", {
    staticClass: "dp-f justify-content-between p3"
  }, [_c("span", {
    staticClass: "h28"
  }, [_vm._v("用户统计表")]), _c("w-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "daterange",
      clearable: false
    },
    on: {
      change: _vm.getTopUser
    },
    model: {
      value: _vm.topUserQuery.created_at_between,
      callback: function ($$v) {
        _vm.$set(_vm.topUserQuery, "created_at_between", $$v);
      },
      expression: "topUserQuery.created_at_between"
    }
  })], 1), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.topUser,
      size: _vm.$store.getters["size"],
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "nickname",
      label: "昵称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.user.nickname) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "user_id",
      label: "专属码"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "order_count",
      label: "单量"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "paid_amount",
      label: "金额"
    }
  })], 1)], 1), _c("div", {
    staticStyle: {
      width: "32%"
    }
  }, [_c("div", {
    staticClass: "dp-f justify-content-between p3"
  }, [_c("span", {
    staticClass: "h28"
  }, [_vm._v("商城统计表")]), _c("w-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "daterange",
      clearable: false
    },
    on: {
      change: _vm.getSite
    },
    model: {
      value: _vm.siteQuery.created_at_between,
      callback: function ($$v) {
        _vm.$set(_vm.siteQuery, "created_at_between", $$v);
      },
      expression: "siteQuery.created_at_between"
    }
  })], 1), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.site,
      size: _vm.$store.getters["size"],
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "site",
      label: "商城"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "order_count",
      label: "单量"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "paid_amount",
      label: "金额"
    }
  })], 1)], 1), _c("div", {
    staticStyle: {
      width: "32%"
    }
  }, [_c("div", {
    staticClass: "dp-f justify-content-between p3"
  }, [_c("span", {
    staticClass: "h28"
  }, [_vm._v("运输方式统计")]), _c("w-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "daterange",
      clearable: false
    },
    on: {
      change: _vm.getTrans
    },
    model: {
      value: _vm.transQuery.created_at_between,
      callback: function ($$v) {
        _vm.$set(_vm.transQuery, "created_at_between", $$v);
      },
      expression: "transQuery.created_at_between"
    }
  })], 1), _c("el-table", {
    staticClass: "w100",
    attrs: {
      data: _vm.trans,
      size: _vm.$store.getters["size"],
      border: "",
      stripe: "",
      "max-height": "400"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "运输渠道"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.transFee.title) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "order_count",
      label: "单量"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "paid_amount",
      label: "金额"
    }
  })], 1)], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };